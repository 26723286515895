import { useTheme } from "@rocket-resume/hooks";
import { cn } from "@rocket-resume/utils";
import logoWhite from '../assets/LogoLight.svg'
import logoBlack from '../assets/LogoDark.svg'

type Props = {
  size?: number;
  className?: string;
};

export const Icon = ({ size = 32, className }: Props) => {
  const { isDarkMode } = useTheme();

  let src;

  switch (isDarkMode) {
    case false: {
      src = logoBlack;
      break;
    }
    case true: {
      src = logoWhite;
      break;
    }
  }

  return (
    <img
      src={src}
      width={size}
      height={size}
      alt="Rocket Resume"
      className={cn("rounded-sm", className)}
    />
  );
};
